import React from 'react'
import {FormGroup, InputGroup, TextArea, Label, Tabs, Tab, TagInput, Icon, Spinner} from '@blueprintjs/core'
import {Column, Row} from '../flex'
import Feature from '../../models/feature'
import Language, {LANGUAGES} from '../../models/language'
import {Tooltip2} from "@blueprintjs/popover2";
import {axios} from "../../common";
import deepEqual from "deep-equal";
import Notifier from "../../notifier";

interface Props {
  feature: Feature
  field: string
  fieldIndex?: number
  fieldName: string | boolean
  languages: Array<Language>
  area: boolean
  onChange: (feature: Feature) => void
}

interface State {
  lang: Language;
  tab: string;
  tags: { [id: string]: string[] };
  isLoading: boolean;
}

export default class Translator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let tags = props.feature.properties[this.props.field] || {};

    this.state = {
      lang: LANGUAGES[0],
      isLoading: false,
      tab: `translator-${this.props.field}-en`,
      tags
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (!deepEqual(prevProps.feature.properties[prevProps.field], this.props.feature.properties[prevProps.field])) {
      this.setState({
        tags: this.props.feature.properties[this.props.field]
      })
    }
  }

  onChange(value: string[], language: Language) {
    const feature = new Feature(this.props.feature.json)

    if (!feature.properties[this.props.field]) {
      feature.properties[this.props.field] = {}
    }

    if (typeof this.props.fieldIndex !== 'undefined') {
      if (typeof feature.properties[this.props.field][this.props.fieldIndex] === 'undefined') {
        feature.properties[this.props.field][this.props.fieldIndex] = {}
      }

      feature.properties[this.props.field][this.props.fieldIndex][language.code] = value
    } else {
      if (typeof feature.properties[this.props.field] !== 'object') {
        feature.properties[this.props.field] = {}
      }

      feature.properties[this.props.field][language.code] = value
    }

    this.props.onChange(feature)
  }

  getDefaultValue() {
    const value = {} as any
    this.props.languages.forEach(language => {
      if (typeof this.props.fieldIndex !== 'undefined') {
        value[this.props.fieldIndex][language.code] = ''
      } else {
        value[language.code] = ''
      }
    })
    return value
  }

  fetchKeywords = async () => {
    this.setState({isLoading: true});

    let title = this.props.feature.properties.title;
    let description = this.props.feature.properties.description;

    if (this.props.feature.properties.title_i18n['en']) {
      title = this.props.feature.properties.title_i18n['en']
    }

    if (this.props.feature.properties.description_i18n && this.props.feature.properties.description_i18n['en']) {
      description = this.props.feature.properties.description_i18n['en']
    }

    const payload = { title, description, lang: this.state.lang.name };

    console.log('payload', payload);

    try {
      const {data} = await axios.post<{ items: string[] }>('https://api.proximi.fi/ai/generate/keywords', payload);

      let translated = data.items;

      if (this.state.lang.code !== 'en') {
        const { data: translations } = await axios.post<{ translation: string }[]>('https://api.proximi.fi/ai/translate/keywords', {
          keywords: data.items,
          lang: this.state.lang.name
        });

        translated = translations.map(t => t.translation)
      }

      const feature = new Feature(this.props.feature.json);
      if (!feature.properties[this.props.field]) {
        feature.properties[this.props.field] = {}
      }

      feature.properties[this.props.field][this.state.lang.code] = translated;
      this.props.onChange(feature);
      this.setState({isLoading: false});
    } catch (e) {
      Notifier.failure("Request failed, please try again")
    }
  }

  renderTextField(language: Language) {
    let fieldValue = this.props.feature.properties[this.props.field] || this.getDefaultValue()

    let value;
    if (typeof this.props.fieldIndex !== 'undefined') {
      const indexValue = fieldValue[this.props.fieldIndex] || {}
      value = indexValue[language.code] || ['']
    } else {
      value = fieldValue[language.code] || ['']
    }

    return <Tooltip2 content={"Press enter to confirm word"} targetProps={{
      style: {
        width: '100%',
      }
    }}>
      <Row flex={1} className={"ai-c"}>
        <TagInput
          inputProps={{
            style: {
              direction: language.rtl ? 'rtl' : 'ltr',
              flex: 1,
              width: '100%'
            }
          }}
          fill={true}
          values={value}
          onChange={values => this.onChange(values as string[], language)}
        />

        { !this.state.isLoading && <Tooltip2 content={"Generate with AI"} >
          <Icon
            icon={"cloud"}
            style={{cursor: 'pointer', paddingLeft: 12}}
            color={"green"}
            onClick={this.fetchKeywords}
          />
        </Tooltip2> }

        { this.state.isLoading && <div style={{ width: 24 }}><Spinner size={16}/></div> }
      </Row>
    </Tooltip2>
  }

  renderTranslation(language: Language) {
    return <Tab
      key={`translator-${this.props.field}-${language.code}`}
      id={`translator-${this.props.field}-${language.code}`}
      title={language.code}
      panel={<Column flex={1}>
        <FormGroup>
          { this.renderTextField(language) }
        </FormGroup>
      </Column>}
    />
  }

  render() {
    return <Column flex={1} className="edit-block translator">
      { this.props.fieldName && <Label style={{marginBottom: 0}}>{ this.props.fieldName }</Label> }
      <Tabs selectedTabId={this.state.tab} onChange={(tab: string) => {
        if (this.state.tab !== tab) {
          const langCode = tab.split('-')[2];
          const lang = LANGUAGES.find(l => l.code === langCode)!;
          this.setState({ tab, lang })
        }
      }}>
        { this.props.languages.map(language => this.renderTranslation(language)) }
      </Tabs>
      <p style={{ fontSize: 10 }}>AI generated keywords are based on title and description</p>
    </Column>
  }
}

