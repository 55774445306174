export default class Language {
  code: string
  name: string
  rtl: boolean

  constructor(data: any) {
    this.code = data.code
    this.name = data.name
    this.rtl = data.rtl || false
  }
}

export const LANGUAGES = [
  new Language({ code: 'en', name: 'English', rtl: false }),
  new Language({ code: 'fi', name: 'Finnish', rtl: false }),
  new Language({ code: 'sv', name: 'Swedish', rtl: false }),
  new Language({ code: 'ar', name: 'Arabic', rtl: true }),
  new Language({ code: 'pl', name: 'Polish', rtl: false }),
  new Language({ code: 'it', name: 'Italian', rtl: false }),
  new Language({ code: 'id', name: 'Bahasa', rtl: false }),
  new Language({ code: 'ru', name: 'Russian', rtl: false }),
  new Language({ code: 'zh', name: 'Chinese', rtl: false }),
]
